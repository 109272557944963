import React from 'react';
import Logo from "../../assets/images/cec-logo.svg";

const Header = () => {
    return (
        <React.Fragment>
            <header className="page__header">
                <div className="page__container">
                    <div className="text-center mb-4 my-sm-auto">
                        <a href={`http://www.cec.local/`} >
                            <img className="header__logo" src={Logo}  alt="The Careers & Enterprise Company" />
                        </a>
                    </div>
                    <div className="text-center my-sm-auto ml-sm-auto">
                        <ul className="list-unstyled list-inline m-0 p-0">
                            <li className="list-inline-item font-14 mr-0 mr-sm-5"><strong>Careers Leader Training Portal</strong></li>
                            <li className="d-none d-sm-inline-block list-inline-item">
                                <a href={`https://tools.jx-cec-pr-7100.k8.careersandenterprise.co.uk/`}  className="cec-btn cec-btn-secondary-teal link-as-button font-14" data-cy="tools-button">Tools</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;
