import axios from 'axios';

const apiUrl = `https://api.clt.jx-cec-pr-7100.k8.careersandenterprise.co.uk`; // This will be replaced by sed
const schoolshubUrl = '' !== process.env.REACT_APP_SCHOOLSHUB_URL
    ? process.env.REACT_APP_SCHOOLSHUB_URL
    : 'https://tools.jx-cec-pr-7100.k8.careersandenterprise.co.uk'; // This will be replaced by sed

const statusCodesToIntercept = [
    400,
    401,
    404,
];

axios.interceptors.response.use(
    response => response,
    error => statusCodesToIntercept.includes(error.response.status)
        ? error.response
        : Promise.reject(error)
);

const SWITCH_USER_HEADER = 'Http-X-Switch-User';

const tokenConfig = (token, userIdToImpersonate) => ({
    headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...userIdToImpersonate && {
            [SWITCH_USER_HEADER]: userIdToImpersonate,
        },
    }
});

const GET = 'get';
const POST = 'post';
const DELETE = 'delete';

const request = (method, urn, useTokenConfig = true, responseType = null, overrideApiUrl = null) => ({data, token, userIdToImpersonate = null, queryParameters, ...rest}) => {
    const axiosMethod = {
        [GET]: axios.get,
        [POST]: axios.post,
        [DELETE]: axios.delete,
    }[method];

    return axiosMethod(
        ...[
            `${overrideApiUrl ?? apiUrl}/${'function' === typeof urn ? urn(rest) : urn}`,
            ...(POST === method) ? [data] : [],
            {
                withCredentials: true,
                ...useTokenConfig && tokenConfig(token, userIdToImpersonate),
                ...responseType && {responseType},
                ...queryParameters && {params: queryParameters},
            }
        ]
    );
};


// Authentication
export const login = request(GET, 'login', true);
export const getRefreshToken = request(GET, 'refresh-token', false);
export const loginSchoolshub = request(POST, 'api/login', true, null, schoolshubUrl);
export const logoutSchoolshub = request(POST, 'api/logout', false, null, schoolshubUrl);

// GET
export const getProfile = request(GET, 'profile');
export const getAllEnrolments = request(GET, 'enrolments');
export const getAllCohorts = request(GET, 'cohorts');
export const getAllCourses = request(GET, 'courses');
export const getAllDeliveryTypes = request(GET, 'deliveryTypes');
export const getAllPayments = request(GET, 'transactions');
export const getAllFailedPaymentProcesses = request(GET, 'failed-payment-processes');
export const getFailedPaymentProcessesMoreInfo = request(GET, ({id}) => `failed-payment-processes/more-info/${id}`);
export const getAllFunds = request(GET, 'funds');
export const getAllRegions = request(GET, 'regions');
export const getAllProviders = request(GET, 'providers');
export const getOwnProvider = request(GET, 'provider');
export const getAllTransactionsExcel = request(GET, 'reports/all-transactions', true, 'blob');
export const getMonthlyClaimExcel = request(GET, 'reports/monthly-claim', true, 'blob');
export const getFinanceReportExcel = request(GET, 'reports/finance-report', true, 'blob');
export const pollPaymentProcessesQueues = request(GET, 'polling/payment-processes');
export const pollFailedPaymentProcesses = request(GET, 'polling/failed-payment-processes');
export const pollEnrolmentsAndCohorts = request(GET, 'polling/enrolments-and-cohorts');

// POST
export const updatePrivacyAgreed = request(POST, 'profile/update-privacy');
export const updateCommsAgreed = request(POST, 'profile/update-comms');
export const updatePersonalInformation = request(POST, 'profile/update-personal-information');
export const updateCourseInformation = request(POST, 'profile/update-course-information');
export const updateSchoolAndLoginInformation = request(POST, 'profile/update-school-login-information');
export const createCohort = request(POST, 'cohorts');
export const updateCohortById = request(POST, ({id}) => `cohorts/${id}`);
export const deleteCohortById = request(DELETE, ({id}) => `cohorts/${id}`);
export const updateEnrolmentById = request(POST, ({id}) => `enrolments/${id}`);
export const deleteEnrolmentById = request(DELETE, ({id}) => `enrolments/${id}`);
export const searchSchoolByUrnOrName = request(POST, 'school/search');
export const searchCareersLeadByFullNameEmailInstitutionNameOrUrn = request(POST, 'careers-leads/search');
export const retryFailedPaymentProcessById = request(POST, ({id}) => `failed-payment-processes/retry/${id}`);

// Bulk actions
export const bulkUpdateContractIssued = request(POST, 'enrolments/bulk-update-contract-issued');
export const bulkUpdateCompleted = request(POST,'enrolments/bulk-update-completed');
export const bulkUpdateCohort = request(POST, 'enrolments/bulk-update-cohort');
